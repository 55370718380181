import { contractAmendmentArray } from './contractAmendment';

export const submissionDataElementArray = [
  {
    id: 'DE051',
    name: 'Vendor Name*',
    family: 'submission',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      isRequired: true,
      max: {
        value: 1000
      }
    },
    description:
      'The name of the responding/invited vendor as it appears in their submission. A vendor may include a consortium. Record the name of the consortium of such cases. For example, Kipling Infrastructure Partnership.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE052',
    name: 'Common Vendor Name',
    family: 'submission',
    type: 'MultiEnumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Tag companies based on their influence within, or control of, the responding vendor. You may tag as many vendors as you deem appropriate; however, multiple tags should generally be reserved for Joint Ventures and consortiums. The consortium of Kipling Infrastructure Partnership, for example, could be tagged with OHL and/or Buttcon.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE053',
    name: 'Submission Incomplete',
    family: 'submission',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "Flag whether the vendor's submission was deemed incomplete. Logging 'Yes' implies incompleteness, 'No' implies completeness.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE054',
    name: 'Submission Non-Compliant',
    family: 'submission',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "Flag whether the vendor's submission was deemed non-compliant. Logging 'Yes' implies non-compliance, 'No' implies compliance.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE055',
    name: 'Submission Disqualified',
    family: 'submission',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "Flag whether the vendor's submission was disqualified. Logging 'Yes' implies disqualification, 'No' implies no disqualification.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE056',
    name: 'Submission Late',
    family: 'submission',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "Flag whether the vendor's submission was late. Logging 'Yes' implies lateness, 'No' implies no lateness.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE057',
    name: 'Submission Rejected',
    family: 'submission',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "Flag whether the vendor's submission was rejected. Logging 'Yes' implies rejection, 'No' implies no rejection.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE059',
    name: 'Joint Venture (JV)',
    family: 'submission',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: 'Flag whether the responding entity is a joint venture, or equivalent.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE058',
    name: 'Comments (if any)',
    family: 'submission',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 300
      }
    },
    description:
      'Use this space to provide any necessary comment or insight regarding the submission, include rationale for rejection, disqualification, etc.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE063',
    name: 'Total Submission Value ($)',
    family: 'submission',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The fixed amount in respect of all fees, costs, expenses, Disbursements labour, materials, insurance, duties, taxes, and all other overhead and any other charges required by law, excluding HST.Record Guaranteed Maximum Price or Net Present Value when they are used in place of a Total Fixed Fee.The amount that is approved on the PCAR2 or at PDSC, as applicable.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE064',
    name: 'Blended Hourly Rate ($)',
    family: 'submission',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'A single rate per hour applicable to all personnel, team members, levels or positions, as applicable, required to deliver the services and is inclusive of all fees, costs, expenses, disbursements, labour, materials, duties, taxes, and all other overhead and any other charges required by law, excluding HST.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE066',
    name: 'Transaction Phase Fee ($)',
    family: 'submission',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The fixed amount in respect of all fees, costs, expenses, disbursements labour, materials, insurance, duties, taxes, and all other overhead and any other charges required by law, excluding HST, associated with the Transaction Phase.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE067',
    name: 'Implementation Phase Fee ($)',
    family: 'submission',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The fixed amount in respect of all fees, costs, expenses, disbursements labour, materials, insurance, duties, taxes, and all other overhead and any other charges required by law, excluding HST, associated with the Implementation Phase.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE068',
    name: 'Pre-Transaction and/or Planning Phase - Fixed Fee ($)',
    family: 'submission',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The fixed amount in respect of all fees, costs, expenses, disbursements labour, materials, insurance, duties, taxes, and all other overhead and any other charges required by law, excluding HST, associated with the Pre-Transaction and/or Planning Phase.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE069',
    name: 'Implementation and Post Implementation Phase - Hourly Rate ($)',
    family: 'submission',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The fixed amount in respect of all fees, costs, expenses, disbursements labour, materials, insurance, duties, taxes, and all other overhead and any other charges required by law, excluding HST, associated with the Implementation and Post Implementation Phase.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE060',
    name: 'Team Member - Name*',
    family: 'submission',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 100
      }
    },
    description: 'The name of the team member as it appears in the submission.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE061',
    name: 'Team Member - Common Name',
    family: 'submission',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: 'The colloquial name of the team member.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE062',
    name: 'Team Member - Category',
    family: 'submission',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: 'Service category and/or role of the team member. ',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE070',
    name: 'Vendor Successful',
    family: 'evaluation',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Specify whether the vendor met the success conditions related to the procurement\'s evaluation (i.e. prequalified, qualified, the Preferred Proponent, etc.). Mark as "Yes" if the vendor was successful, otherwise mark "No". This definition of success does not indicate whether vendors flagged \'Yes\' entered into contract or an agreement.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE065',
    name: 'Ranked',
    family: 'evaluation',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Specify whether the vendor\'s submission was ranked as a result of its evaluation score. Mark as "Yes" if the vendor was ranked, otherwise mark "No". Vendors failing to meet a minimum technical threshold should be marked "No".',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE104',
    name: 'VPP Deduction',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The number of points deducted from the applicable scoring category in the evaluation of a submission as prescribed by IO's Vendor Performance Program.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE072',
    name: 'Vendor Rank',
    family: 'evaluation',
    type: 'Integer',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: 6
    },
    description:
      "The rank of the vendor's submission, stated as an integer (ex. 1, 3, 5). Unevaluated submissions (due to non-compliance, submission rejection, etc.) or submissions failing to meet a minimum technical threshold should be left blank.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE091',
    name: 'Technical Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the vendor for scoring categories related to the evaluation of its technical submission. If a vendor scored 75.33333 out of 100, '75.3333' is the Technical Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE092',
    name: 'Technical Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a vendor may receive related to the evaluation of their technical submission. If a vendor scored 75.33333 out of 100 on their technical score, '100' is the Technical Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE093',
    name: 'Financial Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the vendor for scoring categories related to the evaluation of its financial submission. If a vendor scored 50 out of 75, '50' is the Financial Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE094',
    name: 'Financial Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a vendor may receive related to the evaluation of their financial submission.  If a vendor scored 50 out of 75, '75' is the Financial Max.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE096',
    name: 'Overall Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the vendor. If a vendor scored 50 out of 100, '50' is the Overall Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE097',
    name: 'Overall Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "The maximum possible score a vendor may receive.  If a vendor scored 50 out of 100, '100' is the Overall Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE095',
    name: 'Financial Rating',
    family: 'evaluation',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: "The rating given to the vendor's financial submission.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE105',
    name: 'Phase 1 - Vendor Successful',
    family: 'evaluation',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Specified whether the vendor was successful during Phase 1 of the procurement (i.e. prequalified, awarded, qualified, etc.). Mark as "Yes" if the vendor was successful, otherwise mark "No".',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE106',
    name: 'Phase 1 - Ranked',
    family: 'evaluation',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Specified whether the vendor was ranked during the Phase 1 evaluation process. Mark as "Yes" if the vendor was ranked, otherwise mark "No".',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE107',
    name: 'Phase 1 - Vendor Rank',
    family: 'evaluation',
    type: 'Integer',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: 6
    },
    description:
      "The rank of the vendor's Phase 1 submission, '1' being the highest scoring vendor, '2' the second highest scoring vendor, etc. Unevaluated submissions (due to non-compliance, submission rejection, etc.) or submissions failing to meet a minimum technical threshold should be left blank.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE122',
    name: 'Phase 1 - Financial Rating',
    family: 'evaluation',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "The rating given to the Proponent's Phase 1 - Financial Strength Documentation, or equivalent.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE123',
    name: 'Phase 1 - Overall Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Proponent as a result of Phase 1 evaluations. If a vendor scored 50 out of 100, '50' is the Overall Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE124',
    name: 'Phase 1 - Overall Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a Proponent may receive during Phase 1 evaluations.  If a vendor scored 50 out of 100, '100' is the Overall Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE125',
    name: 'Phase 2 - Vendor Successful',
    family: 'evaluation',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Specify whether the vendor was successful during Phase 2 of the procurement (i.e. prequalified, awarded, qualified, etc.). Mark as "Yes" if the vendor was successful, otherwise mark "No".',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE127',
    name: 'Phase 2 - Ranked',
    family: 'evaluation',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Specified whether the vendor was ranked during the Phase 2 evaluation process. Mark as "Yes" if the vendor was ranked, otherwise mark "No".',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE126',
    name: 'Phase 2 - Vendor Rank',
    family: 'evaluation',
    type: 'Integer',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: 6
    },
    description:
      "The rank of the vendor's Phase 2 submission, '1' being the highest scoring vendor, '2' the second highest scoring vendor, etc. Unevaluated submissions (due to non-compliance, submission rejection, etc.) or submissions failing to meet a minimum technical threshold should be left blank.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE166',
    name: 'Phase 2 - Technical Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the vendor for scoring categories related to the Phase 2 evaluation of its technical submission. If a vendor scored 75.33333 out of 100, '75.3333' is the Technical Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE167',
    name: 'Phase 2 - Technical Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a vendor may receive related to the Phase 2 evaluation of their technical submission. If a vendor scored 75.33333 out of 100 on their technical score, '100' is the Technical Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE142',
    name: 'Phase 2 - Financial Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the vendor for scoring categories related to the evaluation of its Phase 2 financial submission. If a vendor scored 50 out of 75, '50' is the Financial Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE143',
    name: 'Phase 2 - Financial Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a vendor may receive related to the evaluation of their Phase 2 financial submission.  If a vendor scored 50 out of 75, '75' is the Financial Max.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE140',
    name: 'Phase 2 - Overall Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Proponent as a result of Phase 2 evaluations. If a vendor scored 50 out of 100, '50' is the Overall Score.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE141',
    name: 'Phase 2 - Overall Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a Proponent may receive during Phase 2 evaluations.  If a vendor scored 50 out of 100, '100' is the Overall Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE073',
    name: 'Applicant Overall Organization Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Applicant for scoring categories related to the evaluation of the Applicant's overall organization. If a vendor scored 4 out of 5 on their Applicant Overall Organization score, '4' is the Design Team Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE074',
    name: 'Applicant Overall Organization Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score an Applicant may receive related to the evaluation of the Applicant's overall organization. If a vendor scored 4 out of 5 on their Applicant's Overall Organization score, '5' is the Applicant's overall organization Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE075',
    name: 'Applicant Lead Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Applicant for scoring categories related to the evaluation of the Applicant Lead. If a vendor scored 7 out of 10 on their Applicant Lead score, '7' is the Applicant Lead Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE076',
    name: 'Applicant Lead Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score an Applicant may receive related to the evaluation of the Design Team. If a vendor scored 7 out of 10 on their Applicant Lead score, '10' is the Applicant Lead Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE079',
    name: 'Design Team Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Applicant for scoring categories related to the evaluation of the Design Team. If a vendor scored 25 out of 35 on their Design Team score, '25' is the Design Team Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE080',
    name: 'Design Team Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score an Applicant may receive related to the evaluation of the Design Team. If a vendor scored 25 out of 35 on their Design Team score, '35' is the Design Team Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE081',
    name: 'Construction Team Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Applicant for scoring categories related to the evaluation of the Construction Team. If a vendor scored 30 out of 40 on their Construction Team score, '30' is the Construction Team Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE082',
    name: 'Construction Team Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score an Applicant may receive related to the evaluation of the Construction Team. If a vendor scored 30 out of 40 on their Construction Team score, '40' is the Construction Team Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE085',
    name: 'Facilities Maintenance Team Score/Maintenance & Rehabilitation Team Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Applicant for scoring categories related to the evaluation of the Facilities Maintenance Team or Maintenance & Rehabilitation Team, depending on delivery model. If a vendor scored 15 out of 20 on their Facilities Maintenance Team/Maintenance & Rehabilitation Team score, '15' is the Facilities Maintenance Team Score/Maintenance & Rehabilitation Team Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE086',
    name: 'Facilities Maintenance Team Max/Maintenance & Rehabilitation Team Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score an Applicant may receive related to the evaluation of the Facilities Maintenance Team. If a vendor scored 15 out of 20 on their Facilities Maintenance Team score, '20' is the Construction Team Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE087',
    name: 'Operations Team Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Applicant for scoring categories related to the evaluation of the Operations Team. If a vendor scored 30 out of 40 on their Operations Team score, '30' is the Operations Team Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE088',
    name: 'Operations Team Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score an Applicant may receive related to the evaluation of the Operations Team. If a vendor scored 30 out of 40 on their Operations Team score, '40' is the Operations Team Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE188',
    name: 'M&E Subcontractor Submission',
    family: 'evaluation',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Flag whether the M&E Submission met the requirements set out in the RFx document.  ',
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE108',
    name: 'Phase 1 - Proponent Overall Organization Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Proponent for scoring categories related to the Phase 1 evaluation of their overall organization. If a vendor scored 4 out of 5 on their Proponent Overall Organization score during Phase 1, '4' is the Phase 1 - Proponent Overall Organization Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE109',
    name: 'Phase 1 - Proponent Overall Organization Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a Proponent may receive related to the Phase 1 evaluation of their overall organization.  If a vendor scored 4 out of 5 on their Proponent Overall Organization score during Phase 1, '5' is the Phase 1 - Proponent Overall Organization Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE110',
    name: 'Phase 1 - Proponent Lead Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Proponent for scoring categories related to the evaluation of the Proponent Lead. If a vendor scored 25 out of 35 on their Proponent Lead score, '25' is the Proponent Lead Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE111',
    name: 'Phase 1 - Proponent Lead Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score an Proponent may receive related to the evaluation of the Proponent Lead. If a vendor scored 25 out of 35 on their Proponent Lead score, '35' is the Proponent Lead Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE112',
    name: 'Phase 1 - Design Team Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Proponent for scoring categories related to the Phase 1 evaluation of their design team. If a vendor scored 25 out of 30 on their design score during Phase 1, '20' is the Phase 1 - Design Team Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE113',
    name: 'Phase 1 - Design Team Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score Proponents may receive for scoring categories related to the Phase 1 evaluation of their design team. If a vendor scored 25 out of 30 on their design score during Phase 1, '30' is the Phase 1 - Design Team Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE114',
    name: 'Phase 1 - Construction Team Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Proponent for scoring categories related to the Phase 1 evaluation of their construction team. If a vendor scored 25 out of 30 on their construction score during Phase 1, '20' is the Phase 1 - Construction Team Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE115',
    name: 'Phase 1 - Construction Team Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score Proponents may receive for scoring categories related to the Phase 1 evaluation of their construction team. If a vendor scored 25 out of 30 on their construction score during Phase 1, '30' is the Phase 1 - Construction Team Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE116',
    name: 'Phase 1 - Facilities Maintenance Team Score/Maintenance & Rehabilitation Team Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Proponent for scoring categories related to the Phase 1 evaluation of their FM/M&R team. If a vendor scored 25 out of 30 on their FM/M&R score during Phase 1, '20' is the Phase 1 -Facilities Maintenance Team Score/Maintenance & Rehabilitation Team Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE117',
    name: 'Phase 1 - Facilities Maintenance Team Max/Maintenance & Rehabilitation Team Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score Proponents may receive for scoring categories related to the Phase 1 evaluation of their FM/M&R team. If a vendor scored 25 out of 30 on their FM/M&R score during Phase 1, '30' is the Phase 1 - Facilities Maintenance Team Score/Maintenance & Rehabilitation Team Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE118',
    name: 'Phase 1 - Operations Team Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Proponent for scoring categories related to the Phase 1 evaluation of their operations team. If a vendor scored 25 out of 30 on their operations score during Phase 1, '20' is the Phase 1 - Operations Team Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE119',
    name: 'Phase 1 - Operations Teams Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score Proponents may receive for scoring categories related to the Phase 1 evaluation of their operations team. If a vendor scored 25 out of 30 on their operations score during Phase 1, '30' is the Phase 1 - Operations Team Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE120',
    name: 'Phase 1 - Mechanical & Electrical Team Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the Proponent for scoring categories related to the Phase 1 evaluation of their M&E team. If a vendor scored 25 out of 30 on their M&E score during Phase 1, '20' is the Phase 1 - Mechanical & Electrical Team Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE121',
    name: 'Phase 1 - Mechanical & Electrical Team Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score Proponents may receive for scoring categories related to the Phase 1 evaluation of their M&E team. If a vendor scored 25 out of 30 on their M&E score during Phase 1, '30' is the Phase 1 - Mechanical & Electrical Team Max.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE144',
    name: 'Respondent Team Experience Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the respondent for scoring categories related to evaluation of their Team Experience proposal. If a vendor scored 50 out of 75, '50' is the Respondent Team Experience Score.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE145',
    name: 'Respondent Team Experience Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a respondent may receive for scoring categories related to evaluation of their Team Experience proposal. If a vendor scored 50 out of 75, '75' is the Respondent Team Experience Score Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE146',
    name: 'Respondent Team/Key Individual Organization and Experience Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the respondent for scoring categories related to evaluation of their Key Personnel Organization and Experience proposal. If a vendor scored 50 out of 75, '50' is the Key Personnel Organization and Experience Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE147',
    name: 'Respondent Team/Key Individual Organization and Experience Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a respondent may receive for scoring categories related to evaluation of their Key Personnel Organization and Experience proposal. If a vendor scored 50 out of 75, '75' is the Key Personnel Organization and Experience Score Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE148',
    name: 'Understanding of the Assignment and Methodology Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the respondent for scoring categories related to evaluation of their Understanding of the Assignment and Methodology proposal. If a vendor scored 50 out of 75, '50' is the Understanding of the Assignment and Methodology Score",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE149',
    name: 'Understanding of the Assignment and Methodology Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a respondent may receive for scoring categories related to evaluation of their Understanding of the Assignment and Methodology proposal. If a vendor scored 50 out of 75, '75' is the Understanding of the Assignment and Methodology Score Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE160',
    name: 'Organization of Respondent Team Members and Team Lead Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the respondent for scoring categories related to evaluation of their Organization of Respondent Team Members and Team Lead. If a vendor scored 50 out of 75, '50' is the Organization of Respondent Team Members and Team Lead Score.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE161',
    name: 'Organization of Respondent Team Members and Team Lead Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score a respondent may receive for scoring categories related to evaluation of their Organization of RTeam Members and Team Lead. If a vendor scored 50 out of 75, '75' is the Organization of Respondent Team Members and Team Lead Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE162',
    name: 'Qualifications and Experience of Respondent Team Members and Key Individuals Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the respondent for scoring categories related to evaluation of their Qualification and Experience of Team Members and Key Individuals. If a vendor scored 50 out of 75, '50' is the Qualifications and Experience of Respondent Team Members and Key Individuals Score.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE163',
    name: 'Qualifications and Experience of Respondent Team Members and Key Individuals Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score received by the respondent for scoring categories related to evaluation of their Qualification and Experience of Team Members and Key Individuals. If a vendor scored 50 out of 75, '75' is the Qualifications and Experience of Respondent Team Members and Key Individuals Max.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE164',
    name: 'Understanding of the Services and Methodology Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the respondent for scoring categories related to evaluation of their Understanding of the Services and Methodology. If a vendor scored 50 out of 75, '50' is the Understanding of the Services and Methodology Score.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE165',
    name: 'Understanding of the Services and Methodology Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score received by the respondent for scoring categories related to evaluation of their Understanding of the Services and Methodology. If a vendor scored 50 out of 75, '75' is the Understanding of the Services and Methodology Max.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE182',
    name: 'Resourcing Planning Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the respondent for scoring categories related to evaluation of their Resource Planning. If a vendor scored 50 out of 75, '50' is the Resource Planning Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE183',
    name: 'Resourcing Planning Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score received by the respondent for scoring categories related to evaluation of their Resource Planning. If a vendor scored 50 out of 75, '75' is the Resource Planning Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE156',
    name: 'Transaction Phase Fee Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The score assigned to the Transaction Phase Fee component of the vendor's financial submission. If a vendor scored 10 out of 20, '10' is the Transaction Phase Fee Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE157',
    name: 'Transaction Phase Fee Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score assigned to the Transaction Phase Fee component of a vendor's financial submission. If a vendor scored 10 out of 20, '20' is the Transaction Phase Fee Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE158',
    name: 'Implementation Phase Fee Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The score assigned to the Implementation Phase Fee component of the vendor's financial submission. If a vendor scored 10 out of 20, '10' is the Implementation Phase Fee Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE159',
    name: 'Implementation Phase Fee Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score assigned to the Transaction Phase Fee component of a vendor's financial submission. If a vendor scored 10 out of 20, '20' is the Transaction Phase Fee Max.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE184',
    name: 'Pre-Transaction and/or Planning Phase - Fixed Fee Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the respondent for scoring categories related to evaluation of their Pre-Transaction and/or Planning Phase - Fixed Fee. If a vendor scored 50 out of 75, '50' is the Pre-Transaction and/or Planning Phase - Fixed Fee Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE185',
    name: 'Pre-Transaction and/or Planning Phase - Fixed Fee Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score received by the respondent for scoring categories related to evaluation of their Pre-Transaction and/or Planning Phase - Fixed Fee. If a vendor scored 50 out of 75, '75' is the Pre-Transaction and/or Planning Phase - Fixed Fee Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE186',
    name: 'Implementation and Post Implementation Phase - Hourly Rate Score',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The sum of all scores received by the respondent for scoring categories related to evaluation of their Implementation and Post Implementation Phase - Hourly Rate. If a vendor scored 50 out of 75, '50' is the Implementation and Post Implementation Phase - Hourly Rate Score.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE187',
    name: 'Implementation and Post Implementation Phase - Hourly Rate Max',
    family: 'evaluation',
    type: 'Float',
    defaultValue: '',
    placeholder: '',
    validation: {
      beforeDecimal: 3,
      afterDecimal: 2
    },
    description:
      "The maximum possible score received by the respondent for scoring categories related to evaluation of their Implementation and Post Implementation Phase - Hourly Rate. If a vendor scored 50 out of 75, '75' is the Implementation and Post Implementation Phase - Hourly Rate Max.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE189',
    name: 'Contracted Vendor',
    family: 'contract',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Specify whether the vendor entered into an agreement or contract as a result of procurement\'s post-evaluation negotiations. Mark as "Yes" if the vendor entered into such an agreement or contract, otherwise mark "No". Vendors procured as a result of procurements without a formal contract or agreement should be flagged "Yes" in the event that they formally acknowledge their success.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE190',
    name: 'Agreement/Contract Type',
    family: 'contract',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The type of agreement or contract IO has entered into as a result of the procurement/requisition.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE191',
    name: 'Base Agreement/Contract Value',
    family: 'contract',
    type: 'Currency',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The value of the agreement/contract upon execution, excluding taxes, as found in Section 2A of PCAR2, or equivalent.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE193',
    name: 'Currency Type',
    family: 'contract',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: 'Flag whether the fees are to be paid in Canadian or American dollars.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE209',
    name: 'Item Category',
    family: 'contract',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "Select the appropriate option based on the Ministry of Public and Business Service Delivery's (MPBSD), formerly the Ministry of Government and Consumer Services (MGCS), Procurement Activity Report's (PAR) Category Taxonomy.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE202',
    name: 'Effective Date',
    family: 'contract',
    type: 'date',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description: "This is the first 'effective date' listed on the agreement/contract.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE194',
    name: 'Agreement/Contract Expiry Type',
    family: 'contract',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "Specify the method by which the agreement/contract expires: 'Deliverable Completion', 'Fixed Term', or, should the agreement/contract outline a potential or either, 'Latter of Services Completion or Expiry Date'.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE195',
    name: 'Agreement/Contract Expiry Date',
    family: 'contract',
    type: 'date',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      "The date on which the agreement/contract expires. Leave blank if Agreement/Contract Type is 'Deliverable Completion'.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE198',
    name: 'Agreement/Contract Term Length',
    family: 'contract',
    type: 'Integer',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: 6
    },
    description:
      "The duration of agreement/contract's initial term length, in months. For example, if the agreement is to provide services for three years before entertaining an option to extend then enter '36'. Leave blank if Agreement/Contract Expiry Type is 'Deliverable Completion'.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE204',
    name: 'Requisition Documents Received Date',
    family: 'contract',
    type: 'date',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The date on which the PCAR2 was first forwarded to the Program Administrator, or equivalent.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE208',
    name: 'Document(s) Received',
    family: 'contract',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000
      }
    },
    description: 'List all supplemental documents provided alongside the PCAR2, or equivalent.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE205',
    name: 'Requisition Documents Completion Date',
    family: 'contract',
    type: 'date',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Date on which the PCAR2 provided by the Business Unit was deemed materially complete by the Program Administrator.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE206',
    name: 'Requisition Documents Procurement Approval Date',
    family: 'contract',
    type: 'date',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The date on which the requisition was approved by the appropriate Procurement personnel.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE192',
    name: 'PO #',
    family: 'contract',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 100
      }
    },
    description:
      'The Purchase Order (PO) number created for the contracted vendor. Leave blank in the event that no PO was created.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE203',
    name: 'PO Creation Date',
    family: 'contract',
    type: 'date',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The date on which the Purchase Order (PO) was created. Leave blank in the event that the agreement/contract did not result in IO creating a PO or in the event that parties responsible for the create of the PO fall outside of the Procurement Department.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE207',
    name: 'PO Receipt Date',
    family: 'contract',
    type: 'date',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'The date on which IO Procurement receives a Purchase Order from a business unit. Only applicable for grep-related POs.',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE196',
    name: 'Extension Option Exist',
    family: 'contract',
    type: 'Binary',
    defaultValue: '',
    placeholder: '',
    validation: {},
    description:
      'Flag whether the agreement/contract contains at least one extension option whose length is explicitly quantified within the agreement/contract. Excluded are extension options outlining durations \'up to\' an amount of time or generic rights to extend. Flag "Yes" in the event that there is an extension option per the above, otherwise flag "No".',
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE197',
    name: 'Number of Extension Options',
    family: 'contract',
    type: 'Integer',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: 6
    },
    description:
      "The number of extension options within a Fixed Term agreement/contract, if any. For example, if a Fixed Term agreement contains three options to extend then enter '3'. Leave blank if Agreement/Contract Expiry Type is 'Deliverable Completion'.",
    hasDescription: true,
    isDisplayed: false
  },

  {
    id: 'DE199',
    name: 'First Extension Duration',
    family: 'contract',
    type: 'Integer',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: 6
    },
    description:
      "The length of the agreement/contract's first extension option, in months, at the time of execution. For example, if an agreement had three extension options whereby the first such option would extend the agreement/contract by three years then enter '36'. Leave blank if Agreement/Contract Expiry Type is 'Deliverable Completion'. Leave blank if Exention Options Exist is 'No'.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE200',
    name: 'Second Extension Duration',
    family: 'contract',
    type: 'Integer',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: 6
    },
    description:
      "The length of the agreement/contract's second extension option, in months, at the time of execution. For example, if an agreement had three extension options whereby the second such option would extend the agreement/contract by two years then enter '24'. Leave blank if Agreement/Contract Expiry Type is 'Deliverable Completion'. Leave blank if Exention Options Exist is 'No'.",
    hasDescription: true,
    isDisplayed: false
  },
  {
    id: 'DE201',
    name: 'Third Extension Duration',
    family: 'contract',
    type: 'Integer',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: 6
    },
    description:
      "The length of the agreement/contract's third extension option, in months, at the time of execution. For example, if an agreement had three extension options whereby the last such option would extend the agreement/contract by one year then enter '12'. Leave blank if Agreement/Contract Expiry Type is 'Deliverable Completion'. Leave blank if Exention Options Exist is 'No'.",
    hasDescription: true,
    isDisplayed: false
  },
  // {
  //   id: 'DE210',
  //   name: 'Initial Requistion Documents Complete',
  //   family: 'contract',
  //   type: 'Binary',
  //   defaultValue: '',
  //   placeholder: '',
  //   validation: {},
  //   description:
  //     'Flag ‘Yes’ if the set of requisition documents were complete at the point of first receipt from the business unit. Flag ‘No’ if those documents required revisions by the business unit.',
  //   hasDescription: true,
  //   isDisplayed: false
  // },
  ...contractAmendmentArray
];
